<template>
  <div class="container">
    <el-container class="home">
      <el-header class="app_header">
        <el-button class="mainButton homeBtn" type="text" @click="$router.push('/home')">DAWN</el-button>
        <el-button class="mainButton backBtn" icon="el-icon-back" @click="goBack">返回&nbsp | &nbsp&nbsp&nbsp会员中心</el-button>
      </el-header>
      <el-main class="belowTop">
        <div class="topDiv">
          <div class="getVIPDiv">
            <div class="vipTextDiv">{{ vipText }}</div>
            <div class="break"></div> <!-- break -->
            <div class="vipBtnDiv"><el-button class="vipBtn" @click="dialogVisible = true">{{ getVipText }}DAWN会员</el-button></div>
          </div>
          <div class="vipInfoDiv">
            <div class="infoInner">
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info dynamicVal name">{{ nickname }}</div>
                  <div class="info staticText member">{{ membership }}</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info staticText row23 downTimeText">剩余下载次数</div>
                  <div class="info staticText row23 pointsText">积分</div>
                  <div class="info staticText row23 levelText">等级</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info row23 dynamicVal downTime">{{ downloadTimes }}</div>
                  <div class="info row23 dynamicVal points">{{ points }}</div>
                  <div class="info row23 dynamicVal level">{{ level }}</div>
                </div>
              </div>
              <div class="rowDiv">
                <div class="rowCenterDiv">
                  <div class="info staticText expiration expirationText">到期时间</div>
                  <div class="info dynamicVal expiration expirationDate">{{ vipExpiration }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vipBenefitsText">VIP权益</div>
        <div class="bottomDiv">
          <div class="benefitDiv">
            <div class="benefitHeader">进阶版定位报告</div>
            <div class="benefitInfo">您将获得更高质量，更加详细的个人定位报告和更专业的个人规划报告</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitHeader">AI问答不限次数</div>
            <div class="benefitInfo">您将每天不限次数地使用升级版夏怡AI助手</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitHeader">高质量文档下载</div>
            <div class="benefitInfo">您将获得666次免积分下载高质量文档的权益</div>
          </div>
          <div class="benefitDiv">
            <div class="benefitHeader">部分课程限时免费</div>
            <div class="benefitInfo">您将拥有限时免费学习制定课程的机会</div>
          </div>
        </div>
        <el-dialog
          title="会员充值"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <div class="dialogText">购买会员请扫码微信联系</div>
          <div class="imgDiv" @click="courseClicked(item)">
            <img :src="vipCodePath" class="codeImg">
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button class="btns" @click="dialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>   
<script>
  import {get_member_information} from "@/network/member";
  export default {
    name: "Membership",
    data() {
      return {
        activeName: "following",
        followers: [],
        following: [],
        curView_page: [],
        currentPage:1,
        pagesize:10,
        files_count:0,
        vipText: 'VIP年卡送6个月',
        getVipText: '开通', //'开通' or '续费'
        nickname: '',
        membership: '',
        downloadTimes: 0,
        points: 0, 
        level: 0,
        vipExpiration: '',
        dialogVisible: false,
        vipCodePath: require('@/assets/wechatCodeForVIP.jpeg')
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },
    },
    created() {
      this.email = this.$store.getters.email;
      get_member_information(this.email).then((res) => {
        if (res !== undefined && res !== null) {
          if (res.nickname !== undefined && res.nickname !== null){
            this.nickname = res.nickname;
          }
          else {
            this.nickname = this.email;
          }
          this.membership = res.vip === 0 ? '普通用户': '会员'; //1 是会员，0 不是会员
          this.getVipText = res.vip === 0 ? '开通' : '续费';
          this.downloadTimes = res.remaining_downloads;
          this.points = res.integral;
          this.level = res.grade;
          this.vipExpiration = res.due_time;
        }
      })
    }
  }
</script>

<style scoped>
  .dialogText {
    color: #7a557b;
    font-weight: bold;
  }
  .codeImg {
    width: 80%;
  }
  .benefitInfo {
    width: 70%;
    margin: 0 auto;
  }
  .benefitHeader {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
  }
  .benefitDiv {
    width: 25%;
    height: 300px;
    border-radius: 10px;
    border: 1px solid #c7c7c7;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .vipBenefitsText {
    border-radius: 10px;
    border: none;
    background-color: #7a557b;
    color: #f3e6f6;
    text-align: center;
    align-content: center;
    margin: 10px auto;
    height: 50px;
    font-size: 25px;
    font-weight: bold;
  }
  .infoInner {
    width: 80%;
    margin: 20px auto;

  }
  .topDiv, .bottomDiv {
    margin: 20px 10px;
    display: flex;
    flex: 1;
    gap: 10px;
  }
  .getVIPDiv {
    width: 60%;
    height: 300px;
    border-radius: 10px;
    border: 1px solid #c7c7c7;
    display: flex;
    flex-wrap: wrap;
  }
  .vipTextDiv {
    font-size: 50px;
    font-weight: bold;
    align-self: center;
    margin: 0 auto;
    margin-top: 50px;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .vipBtnDiv {
    margin: 0 auto;
  }
  .vipBtn {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    margin: 10px 0;
    font-size: 20px;
    background-color: #7a557b;
    color: #f3e6f6;
  }
  .vipBtn:hover {
    background-color: white;
    border: 1px solid #7a557b;
    color: #7a557b;
  }
  .vipInfoDiv {
    width: 40%;
    border-radius: 10px;
    border: 1px solid #c7c7c7;
  }
  .rowCenterDiv {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    width: inherit;
  }
  .nameCol {
    justify-items: end;
  }
  .info {
    border-radius: 10px;
    border: 1px solid #c7c7c7;
    text-align: center;
    align-content: center;
    margin: 10px;
  }
  .breakColDiv {
    width: 100%;
  }
  .name {
    width: 60%;
    min-height: 40px;
  }
  .member {
    width: 40%;
    height: 40px;
  }
  .row23 {
    min-height: 35px;
    padding: 5px;
  }
  .downTimeText, .downTime{
    width: 60%;
  }
  .points, .pointsText {
    width: 25%;
  }
  .level, .levelText {
    width: 15%;
  }
  .expiration {
    min-height: 35px;
    padding: 5px;
  }
  .expirationText {
    width: 40%;
  }
  .expirationDate {
    width: 60%;
  }
  .dynamicVal {
    color: #7a557b;
  }
  .staticText {
    background-color: #7a557b;
    color: #f3e6f6;
    border: none;
  }
  .btns {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    align-items: center;
    margin: 10px 0;
    color: #7a557b;
  }
  .btns:hover {
    background-color: #7a557b;
    color: #f3e6f6;
  }
  .home {
    overflow: hidden;
  }
  .homeBtn {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    margin-left: 30px;
    padding: 0;
    top: 3px;
    left: 0;
  }
  .el-header {
    position: fixed;
    top: 0;
    left:0;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color: #7a557b;
    z-index: 1000;
  }

  .mainButton {
    position: absolute;
    top: 0;
    margin-top: 0;
  }

  .backBtn {
    font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
    color: white;
    background-color: transparent;
    margin-top: 20px;
    left: 100px;
    border: none;
    font-size: 15px;
  }

  .app_header .el-button {
    color: white;
    margin: 10px;
  }

  .el-container {
    margin: 0px;
  }
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .belowTop {
    text-align: center;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }

</style>