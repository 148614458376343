const getters = {
  token: state => state.user.token,
  count: state => state.user.count,
  email: state => state.user.email,
  access_type: state => state.user.access_type,
  prev_window: state => state.user.prev_window,
  permission: state => state.file.permission,
  file_id: state => state.file.file_id,
  file_name: state => state.file.file_name,
  current_time: state => state.file.current_time,
  open_state: state => state.file.open_state,
  new_image: state => state.image.new_image,
  new_doc: state => state.doc.new_doc,
  bag_path: state => state.file.bag_path,
  eti_result: state => state.file.eti_result,
  isCollapse: state => state.home.isCollapse,
  Status: state => state.home.Status,
  departments: state => state.home.departments,
  comp_departments: state => state.home.comp_departments,
  post: state => state.community.post,
  viewing_posts: state => state.community.viewing_posts,
  // notifications: state => state.home.notifications,
}
export default getters
