<template>
  <div>
    <el-page-header @back="goBack" content="DAWN - 个人资料">
    </el-page-header>
    <el-container class="mainContainer">
      <div id="topBox">
        <div id="topBoxContentDiv">
          <h1 id="nickname">{{ myInfo.Nickname }}
            <div id="inlineBtns">
              <el-button class="rightBtn" @click="$router.push('/profile/membership')">会员中心</el-button>
              <el-button class="rightBtn" @click="handleMyFilesClick">我的资源</el-button>
              <el-button class="rightBtn" @click="handleMyPostsClick">我的帖子</el-button>
              <el-button class="rightBtn" @click="$router.push('/profile/my_drafts')">草稿箱</el-button>
              <el-button class="rightBtn" @click="$router.push('/ai/personal_report')">个人定位报告</el-button>
            </div>
          </h1>
          <p id="userName">{{ myInfo.name }}</p>
          <p id="userEmail">邮箱： {{ email }}</p>
          <div id="inlineBtns">
            <el-button class="textBtn" @click="handleFollowersClick('following')">关注 {{ following }}</el-button>
            <el-button class="textBtn" @click="handleFollowersClick('followers')">粉丝 {{ followers }}</el-button>
            <el-button class="rightBtn" @click="changeValue">{{ edit_button }}</el-button>
          </div>
        </div>
      </div>
      <div id="infoBox">
        <div v-if="!isActive">
          <el-form  :model="myInfo" label-width="80px" class="view">
            <el-form-item label="性别："><el-input v-model="myInfo.sex" readonly/></el-form-item>
            <el-form-item label="生日："><el-input v-model="myInfo.birthday" readonly/></el-form-item>
            <el-form-item label="学校："><el-input v-model="myInfo.school" readonly/></el-form-item>
            <el-form-item label="专业："><el-input v-model="myInfo.major" readonly/></el-form-item>
            <el-form-item label="学号："><el-input v-model="myInfo.studentNumber" readonly/></el-form-item>
          </el-form>
        </div>
        <div v-if="isActive">
          <el-form :model="myInfo" label-width="80px" class="active"
                  :class="{ active:isActive===1 }">
            <el-form-item label="邮箱：">
              <el-input v-model="email" readonly>
                <el-button class="email_change" slot="suffix" @click="$router.push('/profile/reset_email')">更换邮箱</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="昵称："><el-input v-model="myInfo.Nickname"/></el-form-item>
            <el-form-item label="姓名："><el-input v-model="myInfo.name"/></el-form-item>
            <el-form-item label="性别：">
              <div style="width:160px;">
                <el-radio v-model="myInfo.sex" label="男">男</el-radio>
                <el-radio v-model="myInfo.sex" label="女">女</el-radio>
              </div>
            </el-form-item>
            <el-form-item label="生日：">
              <el-date-picker type="date" placeholder="选择日期" v-model="myInfo.birthday" style="width: 79%;"/>
            </el-form-item>
            <el-form-item label="学校："><el-input v-model="myInfo.school"/></el-form-item>
            <el-form-item label="专业："><el-input v-model="myInfo.major"/></el-form-item>
            <el-form-item label="学号："><el-input v-model="myInfo.studentNumber"/></el-form-item>
            <el-form-item label="密码："><el-input type="password" v-model="psd"/></el-form-item>
            <el-form-item>
              <div class="btnGroup">
                <el-button class="info_button" id="saveBtn" type="primary" @click="updateValue">保存</el-button>
                <el-button class="editBtn" @click="changeValue">{{ edit_button }}</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>

import index from "vuex";
import {request_get_information, request_reset_information} from "@/network/profile";
import {get_all_followers, get_all_following} from "@/network/community";
import {starLoading} from "@/utils/common";

export default {
  name: "MyInfo.vue",
  watch: {
    '$route'(to, from) { //刷新本页面
      this.getInformation();
    }
  },
  data() {
    return {
      email: this.$store.getters.email,
      myInfo: {
        Nickname: '未填写昵称',
        name:'未填写姓名',
        sex:'未填写',
        birthday:'未填写',
        school:'未填写',
        major:'未填写',
        studentNumber:'未填写',
        // headPortrait:'',
      },
      tempCopy: {},
      psd:'',
      isActive:0,
      readonly:false,
      form_type:"view_css",
      edit_button:"修改我的资料",
      followers: 0,
      following: 0,
      loadingInstance: null,
    }
  },
  methods:{
    goBack() {
      this.$router.back();
    },
    handleFollowersClick(follow_type) {
      this.$router.push({
        name: 'my_follow', params: { 
          follow_type: follow_type
        }
      });
    },
    changeValue(){
      this.isActive=!this.isActive;
      this.readonly=!this.readonly;
      if(this.edit_button=="修改我的资料"){
        this.tempCopy = {};
        this.tempCopy = JSON.parse(JSON.stringify(this.myInfo));
        this.edit_button="放弃修改";
      }
      else{
        this.myInfo = {};
        this.myInfo = JSON.parse(JSON.stringify(this.tempCopy));
        this.edit_button="修改我的资料";
      }
    },
    getInformation(){
      this.email = this.$store.getters.email;
      this.loadingInstance  = starLoading();
      request_get_information(this.email).then((res) => {
        if (res !== undefined && res !== null) {
          this.myInfo.Nickname = this.assignMyInfo(res.nickname, '未填写昵称');
          this.myInfo.name = this.assignMyInfo(res.name, '未填写姓名');
          this.myInfo.sex = this.assignMyInfo(res.sex, '未填写');
          this.myInfo.birthday = (res.birthday !== undefined && res.birthday !== null) ? res.birthday.slice(0,10) : '未填写';
          this.myInfo.school = this.assignMyInfo(res.school, '未填写');
          this.myInfo.major = this.assignMyInfo(res.major, '未填写');
          this.myInfo.studentNumber = this.assignMyInfo(res.studentNumber, '未填写');
        }
        else {
          this.$message({
            type: 'error',
            message: '获取失败!'
          });
        }
        this.loadingInstance.closeLoading();
      })
    },
    assignMyInfo(resField, msg) {
      return (resField !== undefined && resField !== null) ? resField : msg;
    },
    updateValue(){
      this.email = this.$store.getters.email;
      request_reset_information(this.email, this.myInfo.Nickname, this.myInfo.name, this.myInfo.sex, this.myInfo.birthday, this.myInfo.school,
          this.myInfo.major, this.myInfo.studentNumber, this.psd).then((res) => {
        if (res.flag === 1) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
          this.getInformation();
          this.changeValue();
        } else if (res.flag === 2) {
          this.$message({
            type: 'warning',
            message: '修改失败，昵称已存在，请重试'
          });
        } else if (res.flag === 3) {
          this.$message({
            type: 'warning',
            message: '修改失败，密码错误，请重试'
          });
        } else if (res.flag === 0) {
          this.$message({
            type: 'warning',
            message: '修改失败，请稍后再试'
          });
        }
      })
    },
    handleMyPostsClick() {
      this.$router.push({
        name: 'posts_view', params: { 
          uid: this.$store.getters.email
        }
      });
    },
    handleMyFilesClick() {
      this.$router.push({
        name: 'files_view', params: {
          uid: this.$store.getters.email
        }
      });
    }
  },
  created() {
    get_all_following(this.email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        this.following = res.data.length; //后端数据查询时以控制有序
      }
      else {
        this.following = 0;
      }
    }).catch((error) => {
      console.log(error);
    })
    get_all_followers(this.email).then((res) => {
      if (res !== undefined && res.data !== undefined) {
        this.followers = res.data.length; //后端数据查询时以控制有序
      }
      else {
        this.followers = 0;
      }
    }).catch((error) => {
      console.log(error);
    })
    this.getInformation();
  }

}
</script>

<style scoped>
.el-page-header {
  position: fixed;
  top: 0;
  left:0;
  padding-left: 20px;
  width:100%;
  height: 60px;
  justify-content: start;
  align-items: center;
  background-color: #7a557b;
  color: white;
  overflow:hidden;
  z-index: 1000;
}

::v-deep .el-page-header__content {
  color: white;
}

#topBox {
  position: absolute;
  background-color: #f3e6f6;
  color: #303133;
  text-align: start;
  overflow:hidden;
  top: 0;
  left: 0;
  width: 100%;
  margin: 60px 0;
}

#topBoxContentDiv {
  margin-left: 90px;
  width: calc(100% - 180px);
}

#mainContainer {
  width: 100%;
  overflow-x:hidden;
}

#nickname {
  font-size: 50px;
  margin: 20px;
}
#userName {
  font-size: 20px;
  margin: 20px;
}
#userEmail {
  font-size: 12px;
  margin: 20px;
  display: flex inline;
}
#inlineBtns {
  display: inline;
}
#emailDiv {
  display: flex inline;
}
#editBtnDiv {
  display: flex inline;
}

#infoBox {
  text-align: start;
  background-color: #B69DC3;
  margin: 30px 90px;
  color: white;
  text-align: center;
  margin-top: 280px;
  border-radius: 10px;
  overflow:hidden;
  width: 100%;
  height: fit-content;
}

.el-form {
  width: 460px;
  margin: 40px auto;
}
::v-deep .el-form-item__label{
  font-size: 18px;
  color: white;
}
.btnGroup {
  display: flex;
}

.el-button{
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
.rightBtn {
  float: right;
  background-color: white;
  border: 0;
  color: #7a557b;
  margin-left: 5px;
}
.rightBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.editBtn {
  background-color: white;
  border: 0;
  color: #7a557b;
}
.editBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.textBtn {
  background-color: transparent;
  border: 0;
  color: #7a557b;
}
#saveBtn {
  background-color: #f3e6f6;
  border: 0;
  color: #7a557b;
}
#saveBtn:hover {
  background-color: #7a557b;
  border: 0;
  color: #f3e6f6;
}
.active .el-input{
  font-size: 18px;
  width: 300px;
  margin-right: 300px;
}
.active .el-form-item{
  margin-left: 40px;
}
.view .el-input{
  font-size: 18px;
  width: 240px;
  margin-right: 300px;
}
.view .el-form-item{
  margin-left: 140px;
}
.view ::v-deep .el-input__inner{
  border: 0;
  background-color: transparent;
  color: white;
  border-radius: 0;
}
::v-deep .el-input__inner{
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}
.el-radio {
  color: white;
}
::v-deep .el-radio__inner:hover {
  border-color:#7a557b;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #7a557b;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #7a557b;
  border: #f3e6f6;
}

div{
  width: 100%;
}
.el-form-item{
  margin-top: 30px;
}
.email_change {
  padding-right: 10px;
  background: none;
  border: none;
  color: #7a557b;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}
.email_change:hover{
  color: #B69DC3;
}
</style>
