const home = {
    state: {
        isCollapse: true,
        Status: "展开",
        departments: [],
        comp_departments: [],
        // notifications: [],
    },
    mutations: {
        CollapseChange: (state) => {
            state.isCollapse = !state.isCollapse;
            if(state.Status==="展开"){
                state.Status = "收起";
            }
            else state.Status = "展开";
        },
        SET_DEPARTMENTS: (state, departments) => {
            state.departments = departments;
        },
        SET_COMP_DEPARTMENTS: (state, comp_departments) => {
            state.comp_departments = comp_departments;
        },
        // SET_NOTIFICATIONS: (state, notifications) => {
        //     state.notifications = notifications;
        // },
    }
}

export default home