<template>
  <el-container class="home">
    <el-header class="app_header">
      <el-button id="mainButton" type="text" @click="$router.push('/home')">DAWN</el-button>
      <div class="topBarButtons">
        <el-button type="text" @click="$router.push('/home/headline_news')">头条新闻</el-button>
        <el-button type="text" @click="$router.push('/community/home')">经验交流</el-button>
        <el-button type="text" @click="$router.push('/file/home')">文件共享</el-button>
        <el-button type="text" @click="$router.push('/ai/home')">智能AI</el-button>
        <el-button type="text" @click="$router.push('/course/home')">自我提升</el-button>
        <el-button type="text" @click="$router.push('/intelligence')">智能教研</el-button>
        <el-button type="text" @click="$router.push('/contact_us')">联系我们</el-button>
  <!--      通知消息，先注释掉-->
<!--        <el-button class="app_notification" type="text" @click="$router.push('/notification')">-->
<!--          <i class='el-icon-message-solid' style="font-size: 24px;" />-->
<!--        </el-button>-->

  <!--      <el-tag class="head_img"></el-tag>-->
        <el-badge is-dot v-if="hvNotice" class="app_notification">
          <el-dropdown @command="handleClickNotification">
            <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
            <el-dropdown-menu slot="dropdown" class="app_window">
              <el-dropdown-item command="system">系统通知</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-badge>
        <el-dropdown v-else class="app_notification" @command="handleClickNotification">
          <span class="el-dropdown-link"><i class="el-icon-bell"></i></span>
          <el-dropdown-menu slot="dropdown" class="app_window">
            <el-dropdown-item command="system">系统通知</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="app_dropdown" @command="handleClickDropdown">
          <span class="el-dropdown-link"><i class="el-icon-user-solid"></i></span>
          <el-dropdown-menu slot="dropdown" class="app_window">
            <el-dropdown-item command="profile">个人中心</el-dropdown-item>
            <el-dropdown-item command="resetPsd">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!--  图标链接  -->
    <link rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossorigin="anonymous">
      <el-container class="belowTop">
        <el-aside width="collapse">
          <el-menu router :default-active="activeIndex" class="el-menu-vertical" :collapse="isCollapse">
          <el-menu-item index="/home/headline_news"><i class="el-icon-s-marketing"></i><span slot="title">头条新闻</span></el-menu-item>
          <el-menu-item index="/home/collected_news"><i class="el-icon-document"></i><span slot="title">收藏新闻</span></el-menu-item>
          <el-menu-item index="/home/training_units"><i class="el-icon-s-cooperation"></i> <span slot="title">培养单位</span></el-menu-item>
          <el-menu-item index="/home/collected_dps"><i class="el-icon-school"></i> <span slot="title">收藏院校</span></el-menu-item>
          <el-submenu index="/home/department_summary">
            <template slot="title"><i class="el-icon-office-building"></i><span>院校汇总</span></template>
            <div class="search"><el-input placeholder="搜索院校" v-model="keywords" 
              clearable @keyup.enter.native="handleSearchDp"></el-input></div>
            <el-menu class="left-nav-menu" :default-active="activeIndexDp" @select="handleSelectDp">
              <!--          每个研究所-->
              <el-menu-item :index="item" :title="item.dp_name" v-for="item in departments" :key="item.fid">
                {{ item.dp_name }}
              </el-menu-item>
            </el-menu>
          </el-submenu>
          <el-submenu index="/computer/comp_department_summary">
            <template slot="title"><i class="el-icon-s-platform"></i><span>计算机院校汇总</span></template>
            <div class="search"><el-input placeholder="搜索院校" v-model="compDpKeywords" 
              clearable @keyup.enter.native="handleSearchCompDp"></el-input></div>
            <el-menu class="left-nav-menu" :default-active="activeIndexDp" @select="handleSelectCompDp">
              <el-menu-item :index="item" :title="item.dp_name" v-for="item in compDepartments" :key="item.fid">
                {{ item.dp_name }}
              </el-menu-item>
            </el-menu>
          </el-submenu>
          <el-menu-item id="foldBtn" @click="CollapseChange">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
            <span slot="title">{{ status }}</span>
          </el-menu-item>
        </el-menu>
        </el-aside>
        <el-main :class="isCollapse ? 'mainFull' : 'mainSmall'">
          <router-view></router-view>
        </el-main>
      </el-container>
  </el-container>
</template>

<script>

import {get_departments, get_all_university} from "@/network/home";
import {get_notifications} from "@/network/notification";

export default {
  name: "Home",
  data() {
    return {
      drawer: true,
      activeIndex: this.$route.path,
      activeIndexDp: '',
      notifications: [],
      notices: [],
      haveUnread: false,
      isCollapse: this.$store.getters.isCollapse,
      status: this.$store.getters.Status,
      departmentsAll: [],
      compDepartmentsAll: [],
      favDepartmentsAll: [],
      departments: [
        {
          "fid": "加载中",
          "dp_name": "加载中",
          "link": "加载中"
        }
      ],
      compDepartments: [],
      keywords: '',
      compDpKeywords: '',
      hvNotice: false,
    }
  },
  methods: {
    connectWebSocket() {
      var ws = new WebSocket('wss://postgraduate.xsaifor.com/ws'); //https使用wss,http使用ws
      ws.onmessage = this.getMessage;
    },
    getMessage: function (msg) {
      const reader = new FileReader();
      if (msg.data instanceof Blob) {
        reader.readAsText(msg.data, "UTF-8");
        reader.onload = (e) => {
          this.notifications = JSON.parse(reader.result).files
        };
      }
      let tempOffset = 0;
      this.notifications.forEach(notice => {
        this.$notify({
          title: notice.title,
          message: notice.content,
          duration: 0,
          offset: tempOffset,
          onClick: function() {
            window.open(notice.link, '_blank')  //打开新页面跳转
          },
        });
        tempOffset += 100;
      });
    },
    CollapseChange(){
      this.$store.commit('CollapseChange');//修改全局存储
      //修改临时存储
      this.isCollapse = !this.isCollapse;
      if(this.status=="展开"){
        this.status = "收起";
      }
      else this.status = "展开";
    },
    handleSelectDp(key) {
      if (this.activeIndexDp !== key) {
        this.activeIndexDp = key.toString();
        this.titleName = key.dp_name;
        window.sessionStorage.setItem('dpName',key.dp_name);
        this.$router.replace({
          path: '/home/department_summary/'+key.fid.toString(), 
          query: {dpId: key.fid, dpName: key.dp_name}
        });//重新申请页面
      }
    },
    handleSelectCompDp(key) {
      if (this.activeIndexDp !== key) {
        this.activeIndexDp = key.toString();
        this.titleName = key.dp_name;
        window.sessionStorage.setItem('dpName',key.dp_name);
        this.$router.replace({
          path: '/home/comp_department_summary/'+key.fid.toString(), 
          query: {dpId: key.fid, dpName: key.dp_name}
        });//重新申请页面
      }
    },
    handleClickNotification(command) {
      if (command === "system") {
        this.$router.push('/notification/');
      }
    },
    handleClickDropdown(command) {
      if (command === "logout") {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login/');
        })
      }
      else if (command === "profile") {
        this.$router.push('/profile/');
      }
      else if (command === "resetPsd") {
        this.$router.push('/reset_password/');
      }
    },
    handleSearchDp() {
      this.departments = this.departmentsAll.filter((p)=>{
        return p.dp_name.indexOf(this.keywords) !== -1;
      })
    },
    handleSearchCompDp() {
      this.compDepartments = this.compDepartmentsAll.filter((p)=>{
        return p.dp_name.indexOf(this.compDpKeywords) !== -1;
      })
    },
  },
  created() {
    // this.notifications = this.$store.getters.notifications;
    this.connectWebSocket();
    console.log(this.status);
    const email = this.$store.getters.email;
    get_notifications(email).then((res) => {
      let notices = res.data;
      notices.forEach(notice => {
        if (notice.isRead === 0) {
          this.hvNotice = true;
        }
      });
    })
  },
  beforeCreate() {
    const email = this.$store.getters.email;
    get_departments(this.$store.getters.email).then((res) => {
      this.departmentsAll = res.files;
      this.$store.commit("SET_DEPARTMENTS", this.departmentsAll);
    })
    get_all_university(this.$store.getters.email).then((res) => {
      this.compDepartmentsAll = res.data;
      this.$store.commit("SET_COMP_DEPARTMENTS", this.compDepartmentsAll);
    })
  },
  watch: {
    departmentsAll: {
      handler: function() {
        this.departments = this.departmentsAll;
      },
      deep: true,
      immediate: true
    },
    compDepartmentsAll: {
      handler: function() {
        this.compDepartments = this.compDepartmentsAll;
      },
      deep: true,
      immediate: true
    },
  },
}
</script>

<style scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
}

.el-header {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  justify-content: center;
  align-items: center;
  background-color: #7a557b;
  z-index: 1000;
}

#mainButton {
  position: absolute;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  left: 0;
  font-size: 30px;
  margin-left: 30px;
  top: 0;
  margin-top: 0;
}

.app_header .el-button {
  color: white;
  margin: 10px;
}

.el-aside .el-menu {
  overflow-x: hidden;
  text-align: start;
  height: 100%;
  overflow-y: auto;
}

.el-menu-item, .el-submenu-title {
  background-color: transparent !important;
}

.el-submenu .el-menu-item.is-active {
  background-color: #f3e6f6 !important;
}

.el-menu-item.is-active {
  color: #7a517c !important;
}

.el-menu-item:hover, .el-submenu:hover {
  background-color: #d6c5db !important;
}

.el-submenu-title:hover {
  background-color: #d6c5db !important;
}

.left-nav-menu {
  overflow-y: scroll;
  max-height: 400px;
}

#foldBtn {
  bottom: 0;
  left: 0;
}

.el-card {
  text-align: start;
}

.el-main {
  background-color: #f3e6f6;
  color: #333;
  text-align: center;
  flex: 1;
  overflow-y: auto;
  margin-left: 10px;
}

.app_dropdown {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 30px;
}

.el-dropdown-menu__item:hover {
  background-color: #f3e6f6;
  color: #7a517c;
}

.el-icon-user-solid {
  color: white;
  font-size: 20px;
}

.app_notification {
  position: absolute;
  right: 50px;
  top: 20px;
  margin-right: 30px;
}

::v-deep .el-badge__content.is-dot {
  border: none;
}

.el-icon-bell {
  color: white;
  font-size: 20px;
}

.belowTop {
  margin-top: 60px;
  height: calc(100vh - 60px);
  display: flex;
  overflow: hidden;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #9c7dae;
}

.search ::v-deep .el-input__inner{
  height:40px;
  border-radius: 10px;
  width: 200px;
  left:10px;
  right:10px;
  font-size: 12px;
  margin: 10px;
  font-family: 'STFangsong','FangSong', 'STSong', Helvetica, Arial, sans-serif;
}

.search ::v-deep .el-icon-search{
  height:40px;
  border-radius: 10px;
  width: 50px;
  right:20px;
}
.search ::v-deep .el-input__inner:focus{
  border-color: #7a557b;
}
.el-message-box ::v-deep .el-button {
  background-color: #7a517c !important;
}
</style>